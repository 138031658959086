import React, {useEffect, useRef, useState} from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image";

import ReactMarkdown from "react-markdown"
import FsLightbox from 'fslightbox-react';

import Index from "../components/layout"
import ProjectGallery from "../components/gallery/projectGallery";
import ShareButtons from "../components/socialMediaSharing";
import scrollTo from "gatsby-plugin-smoothscroll";
import TransitionLink from "gatsby-plugin-transition-link";

import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const query = graphql`
  query ProductQuery($slug: String!) {
    strapiProducts(slug: { eq: $slug }) {
      name
      size
      slug
      collection_ID
      content
      cta_link
      cta_text
      country
      locality
      image {
        childImageSharp {
            fixed (width: 800 quality:80) {
                srcWebp
            }
            fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
        }
      }
      mineral_collection_type {
      name
      slug
      forSale
      }
      sub_image {
          image {
            childImageSharp {
              fixed (width: 800 quality:80) {
                srcWebp
              }
              fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              src
              }
            }
          }
          alt
      }
    }
  }
`

const Product = ({ data }) => {
    const product = data.strapiProducts

    let productContent = '';

    if (product.content) {
        productContent += (product.content);
    }
    else {
        productContent += ("This " + product.name + " specimen comes from " + product.locality + ", " + product.country + " in the size of " + product.size + " (inventory number #" + product.collection_ID + '). To be directed forward for more information please click on \"' + product.cta_text + '\".');
    }

    let ctaText = '';
    let ctaURL = '';
    let isForSale = product.mineral_collection_type.forSale;

    if (isForSale === true) {
        ctaText += (product.cta_text);
        ctaURL += (product.cta_link);
    }
    else {
        ctaText += ("Contact");
        ctaURL += ("#contact");
    }

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        sourceIndex: 1
    });
    const Images = product.sub_image.map((subImage, i) =>
        subImage.image.childImageSharp.fixed.srcWebp || "/static/0b40c841e3842ea267ece720358b11dc/e1b0b839bdf8b17bae692dabd61a82f1.svg"
    );

    Images.unshift(product.image.childImageSharp.fixed.srcWebp);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            sourceIndex: number
        });
    }

    console.log(Images)

    let projectMainImage = useRef(null);
    let projectTitle = useRef(null);
    let projectText = useRef(null);

    useEffect(() => {
        gsap.from(projectMainImage, 0.8, {
            opacity: 0,
            scale: 1.6,
            ease: "Power2.easeInOut",
            delay: -0.2
        });
        gsap.from(projectTitle, 0.4, {
            opacity: 0,
            y:32,
            delay:0.1
        });
        gsap.from(projectText, 0.4, {
            opacity: 0,
            y: 96,
            delay:0.1
        });
    }, [projectMainImage, projectTitle, projectText]);

    const TRANSITION_LENGTH = .5

    const exitTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPage(),
    }

    const exitReverseTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageReverse(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPage() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateExitPageReverse() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '-60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <Index
            title={product.name }
            description={product.content}
            mainImage={product.image.childImageSharp.fixed.srcWebp}
        >
            <section className="regular-section single-product-wrapper">
                <div ref={el => (projectMainImage = el)} className="sp-img-wrapper" onClick={() => openLightboxOnSlide(0)} onKeyDown={() => openLightboxOnSlide(0)} role="button" tabIndex="0">
                    <Img fluid={product.image.childImageSharp.fluid}
                         alt={product.name}
                    />
                    <span className="mineral-id">#{product.collection_ID}</span>
                </div>
                <div className="sp-details">
                    <h2 ref={el => (projectTitle = el)}>{product.name}</h2>
                    <ul className="sp-content">
                        <li>{product.locality}, {product.country}</li>
                        <li>Locality</li>
                        <li>{product.size}</li>
                        <li>Size</li>
                        <li>#{product.collection_ID}</li>
                        <li>Collection</li>
                    </ul>
                    <ShareButtons product={product}/>
                    <div ref={el => (projectText = el)}>
                        <ReactMarkdown source={productContent} />
                    </div>
                    <a className={'btn-cta'} href={ctaURL}>{ctaText}</a>
                </div>
            </section>
            <ProjectGallery product={product} openLightboxOnSlide={openLightboxOnSlide}/>
            <section className="regular-section more-collections">
                <div className="paginator-big back-to-gallery">
                    <TransitionLink
                        className="read-more"
                        to={`/minerals/${product.mineral_collection_type.slug}`}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                        <i className="icons icon-west"/>
                        <span className="collection-number">Back to the Collection</span>
                    </TransitionLink>
                </div>
            </section>
            <section className="regular-section fslightbox-section">
                <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={Images}
                    sourceIndex={lightboxController.sourceIndex}
                />
            </section>
        </Index>
    )
}

export default Product
