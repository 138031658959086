import React from 'react';
import ProjectCard from "./projectCard";

const LightBox = ({ product, openLightboxOnSlide }) => {



    return (
        <>
            <div className="collection-minerals">
                {product.sub_image.map((subImage, i) =>{
                    return <ProjectCard
                        subImage={subImage}
                        i={i}
                        openLightboxOnSlide={openLightboxOnSlide}
                    />
                })}
            </div>
        </>
    );
}

export default LightBox;
