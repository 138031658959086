import React from "react"

import LightBox from "./fslightbox";

const ProjectGallery = ({ product, openLightboxOnSlide }) => {
    return (
        <section className="regular-section collection-wrapper sp-photos">
            <LightBox
                product={product}
                openLightboxOnSlide={openLightboxOnSlide}
            />
        </section>
    )
}

export default ProjectGallery
