import React from "react"
import Img from "gatsby-image"


const ProjectCard = ({ subImage, i, openLightboxOnSlide }) => {
    let number = i+1;

    return (

            <div className="single-mineral" onClick={() => openLightboxOnSlide(number)} onKeyDown={() => openLightboxOnSlide(number)} role="button" tabIndex="0">
                <Img fluid={subImage.image.childImageSharp.fluid} alt={subImage.alt} />
            </div>
    )
}

export default ProjectCard
