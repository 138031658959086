import React, { Component } from "react"
import Tooltip from "./navigation/tooltip";

class CopyToClipboard extends Component {

    copyToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")

        document.getElementById("copyLink").classList.add("visible");

        setTimeout(() => {
            document.getElementById("copyLink").classList.add("copied");
        }, 10);

        setTimeout(() => {
            document.getElementById("copyLink").classList.remove("copied");
        }, 2000);

        setTimeout(() => {
            document.getElementById("copyLink").classList.remove("visible");
        }, 2010);
    }

    render() {
        const {product} = this.props;

        return (
            <div id="copyLink" className="copyLink">
                <Tooltip text={"The URL copied to clipboard"}>
                    <button onClick={() => this.copyToClipboard()}>
                        <i className="icon-copyLink"/>
                        <textarea
                            ref={(textarea) => this.textArea = textarea}
                            value={"https://rockaholik.netlify.app/minerals/" + product.mineral_collection_type.slug + "/" + product.slug + "/"}
                        />
                    </button>
                </Tooltip>
            </div>
        )
    }
}

export default CopyToClipboard
